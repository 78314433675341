import React from 'react';

import {
    Box,
    Link,
    List,
    ListItem,
    Typography
} from '@mui/material/';

function Footer(props){

    return (
        <Box id="footer" sx={{color: 'white', bgcolor: 'primary.main', p:1}}>
            <List dense>
                <ListItem>Resources</ListItem>
                <ListItem>
                    <Link href="#" variant='body2' color="#fff" underline="none" onClick={props.onHome}>Home</Link>
                </ListItem>
                <ListItem>
                    <Link href="#!" variant='body2' color="#fff" underline="none" onClick={props.onContact}>Contact Us</Link>
                </ListItem>
                <ListItem>
                    <Link href="#!" variant='body2' color="#fff" underline="none" onClick={props.onDisclaimer}>Privacy Policy</Link>
                </ListItem>
            </List>
            <Box sx={{m: 2}}>
                <Box sx={{mb:1}}>
                    <Link href="#" variant="h1" underline="none" color='#fff' onClick={props.onHome}>
                        YieldMaintenance.co
                    </Link>
                </Box>
                <Box>
                    <Typography variant="body1">
                       {/* About Us */}
                    </Typography>
                </Box>
            </Box>
        </Box>
    )
}

export default Footer;