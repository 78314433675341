import React from 'react';

import {Send} from '@mui/icons-material';

import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material/';

function ContactUs(props){

    return (
        <Box id="disclaimer">
            <Dialog open={props.contactUsIsOpen} onClose={props.onClose}>
                <DialogTitle>Contact Us</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Button variant="contained" endIcon={<Send />} target="_blank" href="mailto:hello@yieldmaintenance.co">
                            Send us an email
                        </Button>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </Box>
    )
}

export default ContactUs;