import React, { useState } from 'react';

import {Close, Menu, Home, Mail, PrivacyTip} from '@mui/icons-material';
import {
    AppBar,
    Box,
    Divider,
    Drawer,
    Grid,
    Link,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    IconButton
} from '@mui/material/';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

function Nav(props){

    const [menuIsOpen, setMenuIsOpen] = useState(false);

    const theme = useTheme();
    let isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    const handleMenuClick = ()=>{
        setMenuIsOpen(!menuIsOpen);
    }

    const onMenuHomeClick    = () => {setMenuIsOpen(false); props.onHome();}
    const onMenuContactClick = () => {setMenuIsOpen(false); props.onContact();}
    const onMenuPrivacyClick = () => {setMenuIsOpen(false); props.onDisclaimer();}

    return (
        <Box id="navbar">
            {/* Top Bar */}
            <AppBar id="navbar" position="static">
                <Grid container direction='row' justifyContent="flex-start" alignItems="center" sx={{p: 1, p:1}}>
                    <Grid item xs={2}>
                        <IconButton size="large" edge="start" onClick={handleMenuClick}>
                        <Menu sx={{ color: 'white' }}/>
                        </IconButton>
                    </Grid>
                    <Grid item xs={8} sx={{textAlign: 'center'}}>
                        <Link href="#" variant="h1" underline="none" color='#fff' onClick={onMenuHomeClick}>
                            YieldMaintenance.co
                        </Link>
                    </Grid>
                <Grid item xs={2}></Grid>
            </Grid>
            </AppBar>

            {/* Menu Drawer */}
            <Drawer 
                anchor='left'
                open={menuIsOpen}
                onClose={handleMenuClick}
                PaperProps={{
                sx: {
                    bgcolor: "primary.main", 
                    minWidth: isSmall? "100%":"35%"
                }
                }}
            >
                <List sx={{color: 'white'}}>
                    {/* Menu Header */}
                    <ListItem button key={0}>
                        <ListItemText primary={"Menu"} />
                        <ListItemIcon sx={{justifyContent: 'flex-end'}} onClick={handleMenuClick}>
                        <Close sx={{ color: 'white'}}/>
                        </ListItemIcon>
                    </ListItem>

                    <ListItem button key={1} onClick={onMenuHomeClick}>
                        <ListItemIcon><Home sx={{ color: 'white' }}/></ListItemIcon>
                        <ListItemText primary={"Home"} />
                    </ListItem>
                    <Divider />
                    <ListItem button key={2} onClick={onMenuContactClick}>
                        <ListItemIcon><Mail sx={{ color: 'white' }}/></ListItemIcon>
                        <ListItemText primary={"Contact Us"} />
                    </ListItem>
                    <Divider />
                    <ListItem button key={3} onClick={onMenuPrivacyClick}>
                        <ListItemIcon><PrivacyTip sx={{ color: 'white' }}/></ListItemIcon>
                        <ListItemText primary={"Privacy Policy"} />
                    </ListItem>
                    <Divider />
                </List>
            </Drawer>
        </Box>
    )
}

export default Nav;