import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    typography: {
        fontFamily: [
            '"Work Sans"',
            'Lato',
            'Merriweather',
            '"Arima Madurai"',
        ].join(','),
        h1:{
            fontSize: 28,
            fontWeight: 500,
            color: 'white',
            fontFamily: 'Arima Madurai'
        },
        h2:{
            fontSize: 26,
            fontWeight: 300,
            fontFamily: 'Merriweather',
            color: "#4A4A4A"
        },
        h3:{
            fontSize: 22,
            fontWeight: 300,
            fontFamily: 'Merriweather'
        },
        h4:{
            fontSize: 18,
            fontWeight: 300,
            fontFamily: 'Merriweather'
        },
        subtitle:{
            fontSize: 18,
            fontWeight: 200,
            fontFamily: 'Lato',
            color: "#4A4A4A"
        },
        body1:{
            fontWeight: 200,
            fontFamily: 'Lato'
        },
        button:{
            fontWeight: 700,
            fontFamily: 'Lato',
            color: "white"
        },
        caption:{
            fontWeight: 200,
            fontFamily: 'Work Sans'
        }
    },
    palette: {
        primary:{
            light: '#7EE7AC',
            main: '#2ECC71',
            darker: '#009943',
            contrastText: "#fff",
            transparent: 'rgba(46, 204, 113, .3)'
        },
        secondary: {
            light:"#ba68c8",
            main:"#9c27b0",
            darker: "#7b1fa2",
            contrastText: "#fff",
            transparent: "rgba(156, 39, 176, .3)"
        }
    }
});

export default theme;