import React, { useState, useEffect } from 'react';
import Disclaimer from './Disclaimer';

import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Link,
    Grid,
    TextField,
    Typography,
} from '@mui/material/';

import {Apartment} from '@mui/icons-material';

function PropertySearch(props){

    const [propertyOptions, setPropertyOptions] = useState([]); // all autocomplete options
    const [searchText, setSearchText]           = useState(""); // text typed into autocomplete
    const [isLoading, setIsLoading]             = useState(false)
    const [selectedOption, setSelectedOption]   = useState(null); // option selected from autocomplete dropdown

    const [disclaimerChecked, setDisclaimerChecked] = useState(false);
    const [disclaimerOpen, setDisclaimerOpen]       = useState(false);

    useEffect(()=>{
        if(searchText.length >= 3){
            setIsLoading(true);
            props.onSearch(searchText)
                .then(results=>{
                    let parsedOptions = parseSearchResults(results);
                    setPropertyOptions(parsedOptions);
                    setIsLoading(false);
                })
                .catch(err=>{
                    console.error(err);
                    setIsLoading(false);
                });
        }
    }, [searchText]);

    const parseSearchResults = results => results.map(r=>{return {label: formatOptionLabel(r), value: r.key}});
    const formatOptionLabel = o => `${o.name}, ${o.street_address}, ${o.city}, ${o.state} ${o.postal}`;

    /* Autocomplete Events */ 
    const onInputChange = (e, newInput) => {
        setSearchText(newInput);
    };

    const onOptionSelect = (e, selectedOption) => {
        setSelectedOption(selectedOption);
    };

    /* Button Events */
    const btnIsDisabled = ()=>{return selectedOption==null || !disclaimerChecked};

    const onBtnClick = () =>{
        if(!btnIsDisabled()){
            props.onSubmit(selectedOption.value);
        }
    }

    return (
        <Box id="property-search">
            <Grid container direction='column' justifyContent="flex-start" alignItems="center" spacing={1}>
                <Grid item>
                    <Autocomplete
                        disablePortal
                        autoHighlight
                        sx={{ width: 300 }}
                        loading={isLoading}
                        options={propertyOptions}
                        getOptionLabel={option => option ? option.label:""}
                        renderInput={(params) => <TextField {...params} label="Property Search" variant="outlined"/>}
                        renderOption={(props, option)=>(
                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                {isLoading?
                                    <CircularProgress color="inherit" size={20} />
                                :
                                    <>
                                        <Apartment />
                                        {option.label}
                                    </>
                                }
                            </Box>
                        )}
                        isOptionEqualToValue={(option, value) => {return option.value === value.value}}
                        inputValue={searchText}
                        onInputChange={onInputChange}
                        value={selectedOption}
                        onChange={onOptionSelect}
                    />
                </Grid>
                <Grid item>
                    {/* Disclaimer Checkbox */}
                    <Grid container direction='row' justifyContent='center' alignItems='center' spacing={1} columns={16}>
                        
                        <Grid item xs={2}>
                            <Checkbox checked={disclaimerChecked} onChange={e=>setDisclaimerChecked(e.target.checked)} />
                        </Grid>
                        <Grid item xs={14}>
                            <Typography variant='caption'>
                                I have read and understood the <Link href="#!" onClick={()=>setDisclaimerOpen(true)}>Disclaimer</Link>
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Button
                        variant="contained"
                        disabled={btnIsDisabled()}
                        onClick={onBtnClick}
                        sx={{color: 'white'}}
                    >
                        Check Prepay
                    </Button>
                </Grid>
            </Grid>
            <Disclaimer
                disclaimerOpen={disclaimerOpen}
                onClose={()=>setDisclaimerOpen(false)}
                onAgree={()=>{setDisclaimerOpen(false); setDisclaimerChecked(true)}}
            />
        </Box>
    )
}

export default PropertySearch;