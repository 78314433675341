import React, { useState } from 'react';
import numeral from "numeral";
import DateAdapter from '@mui/lab/AdapterDayjs';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/MobileDatePicker';
import {
    Alert,
    Box,
    Button,
    Checkbox,
    Grid,
    InputAdornment,
    Link,
    MenuItem,
    Paper,
    Tab,
    Tabs,
    TextField,
    Typography,
} from '@mui/material/';
import Disclaimer from './Disclaimer';


const stepdownPrepayTypes = [
    {
        label: '5, 5, 5, 5, 5, 5, 5, 4, 4, 3, 3, 2, 2, 1, 1',
        value: '5%(84),4%(24),3%(24),2%(24),1%(21),O(3)',
        term: 180
    },
    {
        label: '5, 5, 5, 5, 4, 4, 3, 3, 2, 2, 1, 1',
        value: '5%(24),4%(24),3%(24),2%(24),1%(21),O(3)',
        term: 144
    },
    {
        label: '10, 9, 8, 7, 6, 5, 4, 3, 2, 1',
        value: '10%(12),9%(12),8%(12),7%(12),6%(12),5%(12),4%(12),3%(12),2%(12),1%(12)',
        term: 120
    },
    {
        label: '5, 5, 4, 4, 3, 3, 2, 2, 1, 1',
        value: '5%(24),4%(24),3%(24),2%(24),1%(21),O(3)',
        term: 120
    },
    {
        label: 'LO, LO, 8, 7, 6, 5, 4, 3, 2, 1',
        value: 'LO(24),8%(12),7%(12),6%(12),5%(12),4%(12),3%(12),2%(12),1%(12)',
        term: 120
    },
    {
        label: '5, 5, 5, 5, 5, 5, 4, 3, 2, 1',
        value: '5%(72),4%(12),3%(12),2%(12),1%(12)',
        term: 120
    },
    {
        label: '3, 3, 3, 2, 2, 2, 1, 1, 1, 1',
        value: '5%(72),4%(12),3%(12),2%(12),1%(12)',
        term: 120
    },
    {
        label: 'LO,LO, 5, 4, 3, 2, 1',
        value: 'LO(24),5%(12),4%(12),3%(12),2%(12),1%(12)',
        term: 84
    },
    {
        label: '5, 4, 3, 2, 1',
        value: '5%(12),4%(12),3%(12),2%(12),1%(12)',
        term: 60
    },
    {
        label: '3, 2, 1',
        value: '3%(12),2%(12),1%(12)',
        term: 60
    },
]

function PPCalcForm(props){

    const [formError, setFormError]                 = useState(false);
    const [selectedPrepayTab, setSelectedPrepayTab] = useState('ym');
    const [opb, setOPB]                             = useState("");
    const [opbError, setOPBError]                   = useState(false);
    const [noteRate, setNoteRate]                   = useState("");
    const [noteRateError, setNoteRateError]         = useState(false);
    const [firstPmtDt, setFirstPmtDt]               = useState(null);
    const [firstPmtDtError, setFirstPmtDtError]     = useState(false);
    const [loanTerm, setLoanTerm]                   = useState("");
    const [loanTermError, setLoanTermError]         = useState(false);
    const [ioTerm, setIOTerm]                       = useState("");
    const [ioTermError, setIOTermError]             = useState(false);
    const [amTerm, setAMTerm]                       = useState("");
    const [amTermError, setAMTermError]             = useState(false);
    const [stepdownType, setStepdownType]           = useState(stepdownPrepayTypes[0].value);
    const [accrualMethod, setAccrualMethod]         = useState(1);
    const [availableStepdownOptions, setAvailableStepdownOptions] = useState(stepdownPrepayTypes);

    const [disclaimerChecked, setDisclaimerChecked] = useState(false);
    const [disclaimerOpen, setDisclaimerOpen]       = useState(false);
    const [disclaimerError, setDisclaimerError]     = useState(false);

    // just for DEV purposes
    const autofillForm = ()=>{
        setOPB(3000000);
        setNoteRate(3.5);
        setFirstPmtDt('2015-1-1');
        setStepdownType("10%(12),9%(12),8%(12),7%(12),6%(12),5%(12),4%(12),3%(12),2%(12),1%(12)");
        setLoanTerm(120);
        setIOTerm(24);
        setAMTerm(360);
        setAccrualMethod(1);
    }

    /* Input Validation and Formatting */
    const handleOPBChange = (value, setter) => {
        if(value==="test"){// Just for dev purpose
            autofillForm();
            return;
        }
        setter(value);
    }

    const handleOPBBlur = value => {validateOPB(); setOPB(numeral(value).format('0,0.00'))}

    const handleNoteRateChange = value => setNoteRate(value);

    const handleNoteRateBlur = value => {validateNoteRate(); setNoteRate(numeral((value < 1? value*100:value)).format('0.00') || "")};

    const handleLoanTermBlur = () => {validateLoanTerm(); filterStepdownOptions()};

    const handlePrepayTabChange = () => setSelectedPrepayTab(selectedPrepayTab === 'sd' ? 'ym':'sd');

    const handleStepdownTypeChange = (e) => setStepdownType(e.target.value);

    const handleAccrualMethodChange = (e) => setAccrualMethod(e.target.value);


    const validateOPB = () => {setOPBError(numeral(opb).value() < 1); return !(numeral(opb).value() < 1)};

    const validateNoteRate = () => {setNoteRateError(numeral(noteRate).value()<.001); return !(numeral(noteRate).value()<.001)};

    const validateFirstPmtDt = () => {setFirstPmtDtError(!firstPmtDt || firstPmtDt > new Date()); return !(!firstPmtDt || firstPmtDt > new Date())};

    const validateLoanTerm = () => {setLoanTermError(loanTerm < 1); return !(loanTerm < 1)}

    const validateIOTerm = () => {setIOTermError(ioTerm > loanTerm); return !(ioTerm > loanTerm)}

    const validateAMTerm = () => {setAMTermError(amTerm < loanTerm); return !(amTerm < loanTerm)}

    const validateDisclaimer = () => {setDisclaimerError(!disclaimerChecked); return disclaimerChecked}

    const validateForm = () => {

        let opbValid = validateOPB();
        let intValid = validateNoteRate();
        let pmtValid = validateFirstPmtDt();
        let ltmValid = validateLoanTerm();
        let iotValid = validateIOTerm();
        let amtValid = validateAMTerm();
        let dscValid = validateDisclaimer();

        let formValid = (opbValid && intValid && pmtValid && ltmValid && iotValid && amtValid && dscValid);
        setFormError(!formValid);
        return formValid;
    }

    /* Button Events */

    const onBtnClick = () => {
        if(!validateForm()){
            return;
        }

        let formData = {
            opb      : numeral(opb).value(),
            noteRate : numeral(noteRate).value()/100,
            ioTerm   : numeral(ioTerm).value() || 0,
            amTerm   : numeral(amTerm).value() || 360,
            loanTerm : numeral(loanTerm).value() || 120,
            ppStr    : selectedPrepayTab === 'sd' ? stepdownType : `YM(${loanTerm})`,
            firstPmtDt,
            accrualMethod
        }

        props.onSubmit(formData);
    }

    /* Other Functions */
    // filter stepdown prepay options based on loan term
    const filterStepdownOptions = () => {
        if(selectedPrepayTab==='sd'){
            let availableOptions = stepdownPrepayTypes.filter(s=>loanTerm<1||s.term<=loanTerm);
            let availableOptionValues = availableOptions.map(o=>o.value);
            if(!availableOptionValues.includes(stepdownType)){
                setStepdownType("");
            }
            setAvailableStepdownOptions(availableOptions);
        }
    }

    return (
        <Box id="calc-section">
            <Grid container direction='column' alignItems="center">
                <Grid item>
                    <Typography variant="body1">
                        or enter details:
                    </Typography>
                </Grid>
                <Grid item>
                    <Box sx={{m: 2}}>
                        <Paper elevation={0} variant="outlined" sx={{p:1}}>
                            {/* FORM */}
                            <Box component="form" sx={{'& .MuiTextField-root': {m: .5, width: '25ch'}}}>
                                <Grid container direction='column' justifyContent="space-between" alignItems="center" spacing={2}>
                                    {/* Form Error */}
                                    {formError &&
                                        <Grid item>
                                            <Alert severity="error">Please correct the errors below.</Alert>
                                        </Grid>
                                    }
                                    {/* PP Type Toggle */}
                                    <Grid item>
                                        <Tabs 
                                            value={selectedPrepayTab}
                                            onChange={handlePrepayTabChange}
                                            >
                                            <Tab value="ym" label="Yield Maintenance" />
                                            <Tab value="sd"  label="Stepdown" />
                                        </Tabs>
                                    </Grid>
                                    {/* OPB */}
                                    <Grid item>
                                        <TextField 
                                            fullWidth
                                            label="Original Balance"
                                            placeholder="3,000,000"
                                            variant="outlined"
                                            value={opb}
                                            onChange={e=>handleOPBChange(e.target.value, setOPB)}
                                            onBlur={e=>handleOPBBlur(e.target.value, setOPB)}
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                            }}
                                            error={opbError}
                                            helperText={opbError?"Please enter a value greater than $1":""}
                                        />
                                    </Grid>
                                    {/* Note Rate */}
                                    <Grid item>
                                        <TextField
                                            label="Interest Rate"
                                            placeholder="3.75"
                                            variant="outlined"
                                            type="number"
                                            value={noteRate}
                                            onChange={e=>handleNoteRateChange(e.target.value, setNoteRate)}
                                            onBlur={e=>handleNoteRateBlur(e.target.value, setNoteRate)}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                            }}
                                            error={noteRateError}
                                            helperText={noteRateError?"Please enter a positive value":""}
                                        />
                                    </Grid>
                                    {/* First PMT Dt */}
                                    <Grid item>
                                        <LocalizationProvider dateAdapter={DateAdapter}>
                                            <DatePicker
                                                clearable
                                                disableFuture
                                                label="First Payment Date"
                                                inputFormat="MM/DD/YYYY"
                                                value={firstPmtDt}
                                                onChange={newValue=>setFirstPmtDt(newValue)}
                                                onBlur={validateFirstPmtDt}
                                                renderInput={(params) => <TextField {...params} variant="outlined" placeholder="3/25/2015" />}
                                                error={firstPmtDtError}
                                                helperText={firstPmtDtError? "Please enter a date in the past":""}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    {/* Loan Term */}
                                    <Grid item>
                                        <TextField
                                            label="Loan Term (Months)"
                                            placeholder="120"
                                            variant="outlined"
                                            type="number"
                                            value={loanTerm}
                                            onChange={e=>setLoanTerm(numeral(e.target.value).value()||"")}
                                            onBlur={handleLoanTermBlur}
                                            error={loanTermError}
                                            helperText={loanTermError?"Please enter a loan term greater than 1":""}
                                        />
                                    </Grid>
                                    {/* IO Term */}
                                    <Grid item>
                                        <TextField
                                            label="Interest Only Term (Months)"
                                            placeholder="24"
                                            variant="outlined"
                                            type="number"
                                            value={ioTerm}
                                            onChange={e=>setIOTerm(numeral(e.target.value).value())}
                                            onBlur={validateIOTerm}
                                            error={ioTermError}
                                            helperText={ioTermError?"Please enter a term less than or equal to the loan term":""}
                                        />
                                    </Grid>
                                    {/* Am Term */}
                                    <Grid item>
                                        <TextField
                                            label="Amortization Term (Months)"
                                            placeholder="360"
                                            variant="outlined"
                                            type="number"
                                            value={amTerm}
                                            onChange={e=>setAMTerm(numeral(e.target.value).value())}
                                            onBlur={validateAMTerm}
                                            error={amTermError}
                                            helperText={amTermError?"Please enter a term greater than or equal to the loan term":""}
                                        />
                                    </Grid>
                                    {/* If Stepdown: What type? */}
                                    {selectedPrepayTab==="sd" &&
                                        <Grid item>
                                            <TextField select label="Stepdown Type" value={stepdownType} onChange={handleStepdownTypeChange} variant="outlined">
                                                {availableStepdownOptions.map((s,i)=>(
                                                    <MenuItem key={`sd-${i}`} value={s.value}>{s.label}</MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>
                                    }
                                    {/* Accrual Method */}
                                    <Grid item>
                                        <TextField select label="Accrual Method" value={accrualMethod} onChange={handleAccrualMethodChange} variant="outlined">
                                        <MenuItem key={1} value={1}>ACT/360</MenuItem>
                                        <MenuItem key={2} value={2}>30/360</MenuItem>
                                        <MenuItem key={3} value={3}>ACT/ACT</MenuItem>
                                        </TextField>
                                    </Grid>
                                    {/* Disclaimer Checkbox */}
                                    <Grid item>
                                        <Grid container direction='row' justifyContent='center' alignItems='center' spacing={1} columns={16}>
                                            <Grid item xs={2}></Grid>
                                            <Grid item xs={2}>
                                                <Checkbox checked={disclaimerChecked} onChange={e=>setDisclaimerChecked(e.target.checked)} />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant='caption'>
                                                    I have read and understood the <Link href="#!" onClick={()=>setDisclaimerOpen(true)}>Disclaimer</Link>
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        {disclaimerError && <Alert severity="error">Please read and check the disclaimer</Alert>}
                                    </Grid>
                                    {/* Submit */}
                                    <Grid item>
                                        <Button onClick={onBtnClick} disabled={!disclaimerChecked} variant="contained" sx={{color: 'white'}}>Calculate Prepay</Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Paper>
                    </Box>
                </Grid>
            </Grid>
            <Disclaimer
                disclaimerOpen={disclaimerOpen}
                onClose={()=>setDisclaimerOpen(false)}
                onAgree={()=>{setDisclaimerOpen(false); setDisclaimerChecked(true)}}
            />
        </Box>
    )
}

export default PPCalcForm;