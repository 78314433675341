import React from 'react';

import {
    Box,
    Grid,
    Typography
} from '@mui/material/';

function Splash(props){
    return (
        <Box id="splash">
            <Grid container direction='column' justifyContent="flex-start" alignItems="center" textAlign='center' spacing={2}>
                <Grid item sx={{p:1}}>
                    <Typography variant="h2">
                        Loan Prepayment Simplified.
                    </Typography>
                </Grid>
                <Grid item sx={{p:2}}>
                    <Typography variant="subtitle">
                        Full Accuracy. Total Transparency. Completely Wholesome.
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    )
}

export default Splash;