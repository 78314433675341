import React from 'react';
import numeral from "numeral";
import dayjs   from "dayjs";
import ReactGA from "react-ga4";

import { Chart as ChartJS, registerables } from 'chart.js';
import { Line }      from 'react-chartjs-2'
import annotationPlugin     from 'chartjs-plugin-annotation';

import {
    Box,
    Button,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography
} from '@mui/material/';
import { useTheme } from '@mui/material/styles';

ChartJS.register(annotationPlugin, ...registerables);


function ResultPage(props){
    ReactGA.send({hitType: 'pageview', page_title: 'results'});

    const theme = useTheme();
    const results = props.calcResult;

    const onBackBtnClick = ()=>{
        props.onBack();
    }

    let tableData = [
        {
            item: 'Original Principal Balance',
            value: numeral(results.opb).format('$0,0')
        },
        {
            item: 'Months Elapsed',
            value: results.monthsElapsed
        },
        {
            item: `Current Balance (${dayjs(results.amTable[results.monthsElapsed]?.periodDt).subtract(1,'d').format('MM/DD/YYYY')})`,
            value: numeral(results.amTable[results.monthsElapsed]?.endingBalance).format('$0,0')
        },
        {
            item: 'Prepay Type',
            value: results.currentPPType
        },
        {
            item: 'Estimated Prepay Penalty',
            value: numeral(results.prepayAmount).format('$0,0')
        }
    ];

    const chart = {
        options: {
            responsive: true,
            plugins:{
                legend:{
                    display: false
                },
                title:{
                    display: true,
                    text: "Amortization"
                },
                annotation: {
                    annotations: {
                        line1: {
                            type: 'line',
                            xMin: results.monthsElapsed,
                            xMax: results.monthsElapsed,
                            borderColor: theme.palette.primary.main,
                            borderWidth: 4,
                        },
                        label1: {
                            type: 'label',
                            xValue: results.monthsElapsed,
                            xAdjust: 200,
                            yValue: results.amTable[results.monthsElapsed].endingBalance,
                            yAdjust: 10,
                            // backgroundColor: 'rgba(245,245,245)',
                            content: ['Payoff', dayjs(results.amTable[results.monthsElapsed]?.periodDt).subtract(1,'d').format('MM/DD/YYYY')],
                            font: {
                                family: 'Lato',
                                size: 12
                            },
                            callout: {
                                enabled: true,
                                side: 5
                            }
                        }
                    }
                }
            },
        },
        data:{
            labels: results.amTable.map(r=>r.period),
            datasets: [{
                label: 'Amortization',
                data: results.amTable.map(r=>r.endingBalance),
                fill: 'origin',
                borderColor: theme.palette.secondary.main,
                backgroundColor: theme.palette.secondary.transparent,
                borderWidth: 1
            }]
        }
    }

    return (
        <Box id="results-page">
            <Box id="results-page-header">
                <Grid container direction='column' justifyContent="flex-start" spacing={2}>
                    <Grid item>
                        <Grid container direction='row' justifyContent="space-between" alignItems="flex-end">
                            <Grid item><Typography variant="h3">Prepayment Results:</Typography></Grid>
                            <Grid item><Button onClick={onBackBtnClick} variant='contained' sx={{color: 'white'}}>Start Over</Button></Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            <Box id="results-page-table" sx={{mt:2}}>
                <TableContainer component={Paper}>
                    <Table>
                        <TableBody>
                            {tableData.slice(0,-1).map((r,i)=>(
                                <TableRow key={`result-grid-${i}`}>
                                    <TableCell component="th" scope="row">{r.item}</TableCell>
                                    <TableCell align="left">{r.value}</TableCell>
                                </TableRow>
                            ))}
                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell component="th" scope="row">{tableData[4].item}</TableCell>
                                <TableCell align="left">
                                    <Typography variant="h4" color="primary">
                                        {tableData[4].value}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            <Box id='results-page-graph' sx={{mt: 2}}>
                <Line options={chart.options} data={chart.data} />
            </Box>
        </Box>
    )
}

export default ResultPage;