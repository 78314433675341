import React, { useState } from 'react';
import ReactGA from "react-ga4";

import Nav from './components/Nav';
import ResultPage from './components/ResultPage';
import Splash from './components/Splash';
import PropertySearch from './components/PropertySearch';
import PPCalcForm from './components/PPCalcForm';
import Footer from './components/Footer';
import Disclaimer from './components/Disclaimer';
import ContactUs from './components/ContactUs';

import {fetchSearchResults, fetchResult, fetchResultByKey} from './API';

/* MUI Imports */
import {
    Box,
    CircularProgress,
    Container
} from '@mui/material/';

function App() {

    const [isLoading, setIsLoading]   = useState(false);
    const [calcResult, setCalcResult] = useState(null);
    const [contactUsIsOpen, setContactUsIsOpen]       = useState(false);
    const [disclaimerIsOpen, setDisclaimerIsOpen] = useState(false);

    const handleOnSearch = async searchQuery => await fetchSearchResults(searchQuery);

    const handleOnFormSubmit = async formData => {
        setIsLoading(true);
        const responseJson = await fetchResult(formData);
        setIsLoading(false);
        setCalcResult(responseJson);
    }

    const handleOnSearchSubmit = async key => {
        setIsLoading(true);
        const responseJson = await fetchResultByKey(key);
        setCalcResult(responseJson);
        setIsLoading(false);

        ReactGA.event('search', {search_term: key});
    }

    const onBack = () => setCalcResult(null);

    return (
        <div className="App">
            {/*--- Nav Bar ---*/}
            <Nav onHome={onBack} onContact={()=>setContactUsIsOpen(true)} onDisclaimer={()=>setDisclaimerIsOpen(true)} />

            {/*--- Page ---*/}
            <Container id="content">
                {isLoading && (
                    <Box sx={{mt:5, mb:50, textAlign: 'center'}}>
                        <CircularProgress color="primary" />
                    </Box>
                )}
                {!isLoading && (
                    <>
                    {calcResult ?
                        <Box sx={{mt:5}}>
                            <ResultPage calcResult={calcResult} onBack={onBack} />
                        </Box>
                        :
                        <>
                            {/* SPLASH */}
                            <Box sx={{mt:5}}>
                                <Splash />
                            </Box>

                            {/* PROPERTY SEARCH */}
                            <Box sx={{mt: 5}}>
                                <PropertySearch onSearch={handleOnSearch} onSubmit={handleOnSearchSubmit} />
                            </Box>

                            {/* PPP CALC FORM */}
                            <Box sx={{mt: 5}}>
                                <PPCalcForm onSubmit={handleOnFormSubmit} />
                            </Box>
                        </>
                    }
                    </>
                )}
            </Container>

            {/* FOOTER */}
            <Box sx={{mt: 5}}>
                <Footer onHome={onBack} onContact={()=>setContactUsIsOpen(true)} onDisclaimer={()=>setDisclaimerIsOpen(true)} />
            </Box>

            {/* DISCLAIMER MODAL */}
            <Disclaimer
                disclaimerOpen={disclaimerIsOpen}
                onClose={()=>setDisclaimerIsOpen(false)}
                onAgree={()=>{setDisclaimerIsOpen(false)}}
            />

            <ContactUs
                contactUsIsOpen={contactUsIsOpen}
                onClose={()=>setContactUsIsOpen(false)}
            />
        </div>
    );
}

export default App;