export async function fetchSearchResults(searchQuery){
    if(searchQuery.length < 3){
        return [];
    }

    const response = await fetch('/search', {
        method: 'POST',
        mode: 'same-origin',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({searchQuery})
    })

    const responseJson = await response.json();
    return responseJson;
}

export async function fetchResult(formData){
    const response = await fetch('/calculate', {
        method: 'POST',
        mode: 'same-origin',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(formData)
    })
    const responseJson = await response.json();

    return responseJson;
}

export async function fetchResultByKey(key){

    const response = await fetch('/calculate_by_key', {
        method: 'POST',
        mode: 'same-origin',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({key})
    });
    const responseJson = await response.json();

    return responseJson;
}