import React from 'react';

import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Typography,
} from '@mui/material/';

function Disclaimer(props){
    let Site = 'Yieldmaintenance.co';

    return (
        <Box id="disclaimer">
            <Dialog open={props.disclaimerOpen} onClose={props.onClose}>
                <DialogContent>

                        <Typography variant='h3'>
                            General Disclaimer
                        </Typography>
                        <br />
                        <Typography variant='body1'>
                            <b>
                                The content of {Site} and its affiliate sites is for general 
                                information purposes only and does not constitute professional advice.
                            </b>
                        </Typography>
                        <br />
                        <Typography variant='body1'>
                            We try to our utmost to provide content that is true and accurate as of the date 
                            of writing or calculating; however, we give no assurance or warranty regarding the 
                            accuracy, timeliness, or applicability of any of the contents. Visitors to {Site} or 
                            to its affiliate sites should not act upon the content or 
                            information without first seeking appropriate professional advice.
                        </Typography>
                        <br />
                        <Typography variant='body1'>
                            {Site} is not intended to be a source for professional advice. Visitors 
                            to this site should always seek the advice of an appropriately qualified 
                            professional. {Site} assumes no responsibility for information contained on this web site 
                            and disclaims all liability in respect of such information. In addition, none of its 
                            content will form any part of any contract between us or constitute any type of offer by {Site}. 
                            Specific disclaimers may apply in addition to certain content or parts of the site. 
                        </Typography>
                        <br />
                        <Typography variant='body1'>
                            {Site} accepts no responsibility for and excludes all liability in connection with browsing 
                            this web site, use of information or downloading any materials from it, including but not 
                            limited to any liability for errors, inaccuracies, omissions, or misleading or defamatory statements.
                        </Typography>
                        <br />
                        <Typography variant='body1'>
                            This web site is provided “as is” and {Site} expressly disclaims any and all warranties, 
                            express or implied, to the extent permitted by law, 
                            including but not limited to warranties of satisfactory quality, merchantability, or fitness 
                            for a particular purpose, with respect to the service or any materials. 
                        </Typography>
                        <br />
                        <Typography variant='body1'>
                            {Site} hereby excludes liability for any claims, losses, demands, or damages of any kind whatsoever 
                            with regard to any information, content, or services provided at our web site, including 
                            but not limited to direct, indirect, incidental, or consequential loss or damages, compensatory 
                            damages, loss of profits, or data, or otherwise.
                        </Typography>
                        <br />
                        <br />

                        <Typography variant='h3'>
                            Disclaimer of Endorsement
                        </Typography>
                        <br />
                        <Typography variant='body1'>
                            Reference within this site to any specific commercial or non-commercial product, process, 
                            or service by trade name, trademark, manufacturer or otherwise does not constitute or imply an 
                            endorsement, recommendation, or favoring by {Site}.
                        </Typography>
                        <br />
                        <br />

                        <Typography variant='h3'>
                            Disclaimer for Links to External, Third Party Websites
                        </Typography>
                        <br />
                        <Typography variant='body1'>
                            Links to external, or third party websites, are provided solely for visitors’ convenience. 
                            Though we try to take precautions, links taken to other sites are done so at your own risk 
                            and {Site} accepts no liability for any linked sites or their content. When you access an 
                            external web site, keep in mind that {Site} has no control over its content.
                        </Typography>
                        <br />
                        <Typography variant='body1'>
                            Any link from {Site} to an external web site does not imply or mean that {Site} endorses 
                            or accepts any responsibility for the content or the use of such web site. It is important 
                            for users to take necessary precautions, especially to ensure appropriate safety from viruses, 
                            worms, Trojan horses and other potentially destructive items.
                        </Typography>
                        <br />
                        <Typography variant='body1'>
                            When visiting external web sites, users should review those websites' privacy policies and other terms of 
                            use to learn more about, what, why and how they collect and use any personally identifiable information.
                        </Typography>
                        <br />
                        <br />

                        <Typography variant='h3'>
                            Privacy Policy
                        </Typography>
                        <br />
                        <Typography variant='body1'>
                            We take online privacy seriously and strive to protect the privacy of the users of this website and those 
                            who subscribe to our services.
                        </Typography>
                        <br />
                        <Typography variant='body1'>
                            <b>Website Traffic:</b> Our service providers, Google and Heroku, record information about how people access the 
                            website in our server logs. This may include but not limited to information such as your IP address (or DNS), 
                            your browser, and your operating system.
                        </Typography>
                        <br />
                        <Typography variant='body1'>
                            <b>E-mail:</b> E-mail sent to the site is considered property of this site, and questions or tips might be published. 
                            In such cases, we will not disclose any personally identifying information.
                        </Typography>
                        <br />
                        <Typography variant='body1'>
                            <b>Website Analytics & Use of Cookies:</b> We may also partner with selected third-party vendors, such as 
                            Google Analytics and others, to allow tracking technologies and remarketing services on {Site} through 
                            the use of first party cookies and third-party cookies, to, among other things, analyze and track 
                            users’ use of the {Site} and its affiliate sites, determine the popularity of certain content and better 
                            understand online activity. By accessing {Site} and its affiliate sites, you consent to the collection 
                            and use of your information by these third-party vendors. You are encouraged to review their privacy 
                            policy and contact them directly for responses to your questions. <b>We DO NOT transfer personal 
                            information to these third-party vendors.</b>
                        </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onClose}>Disagree</Button>
                    <Button onClick={props.onAgree}>Agree</Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}

export default Disclaimer;